import { IonCheckbox, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { personCircle, eye, eyeOff, options, fingerPrint } from "ionicons/icons";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { IonItem, IonLabel, IonInput, IonButton, IonIcon, IonAlert } from '@ionic/react';
import axiosCall from '../data/axios';
import { Preferences } from '@capacitor/preferences';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { NativeBiometric } from "capacitor-native-biometric";

import AuthContext from "../UserContext";
import { getCaptchaToken } from "../Helper";

import UserStatus from "../components/UserStatus";
import { set } from 'date-fns';

function validateEmail(email: string) {
	const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
	return re.test(String(email).toLowerCase());
}

declare var grecaptcha: any;

const Auth: React.FC = () => {
	const history = useHistory();
	const [email, setEmail] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [re_password, setRePassword] = useState<string>("");
	const [name, setName] = useState<string>();
	const [iserror, setIserror] = useState<boolean>(false);
	const [message, setMessage] = useState<string>("");
	const [mode, setMode] = useState<string>(window.location.pathname);
	const { code }: any = useParams();
	const [tNcAgreeRef, setTNcAgreeRef] = useState<boolean>(false);
	const [bmEmail, setBmEmail] = useState<any>();
	const [triggerLogin, setTriggerLogin] = useState<boolean>(false);


	const { authValues, fetchUser, logout } = React.useContext(AuthContext);

	const spinnerOptions = {
		message: 'Please wait...',
		animated: true,
		cssClass: 'drukre-loading',
		showBackdrop: true
	};

	const [spinnerPresent, spinnerDismiss] = useIonLoading();

	if (mode.indexOf('/chanel') > -1) {

		if (code) {

			const veriData = {
				"code": code
			}

			//spinnerPresent(spinnerOptions);

			axiosCall("/api/verifyEmailPhone", 'post', veriData)
				.then((res: any) => {
					//spinnerDismiss();
					//history.push("/login");
				})
				.catch((error: any) => {
					//setMessage("Unable to verify account. Please try again later.");
					//setIserror(true);
					//spinnerDismiss();
				});
		}
	} else if (authValues.authenticated)
		history.goBack();
	/*
	useEffect(() => {
		// Add reCaptcha
		const script = document.createElement('script');
		script.src = "https://www.google.com/recaptcha/enterprise.js?render=6Lf2DOopAAAAAKrW9hrL8_oxVXQrhxm3cYIbYrgd";
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		}
	}, [])
	*/

	function clickMode(newMode: React.SetStateAction<string>) {

		setMode(newMode);

	};

	/*
	function getCaptchaToken(action: any) {
		return new Promise((res, rej) => {
			grecaptcha.enterprise.ready(() => {
				grecaptcha.enterprise.execute('6Lf2DOopAAAAAKrW9hrL8_oxVXQrhxm3cYIbYrgd', {action: action}).then((token: any) => {
					return res(token);
				})
			})
		})
	}
	*/

	const [pushRegToken, setPushRegToken] = useState('');

	useEffect(() => {
		const registerPush = async () => {
			if (Capacitor.isNativePlatform()) {
				await PushNotifications.addListener('registration', token => {
					console.log('Push registration success, token: ' + token.value);
					setPushRegToken(token.value);
				});

				await PushNotifications.addListener('registrationError', error => {
					console.error('Push registration error: ', error);
					throw error;
				});

				let permStatus = await PushNotifications.checkPermissions();

				if (permStatus.receive === 'prompt') {
					permStatus = await PushNotifications.requestPermissions();
				}

				if (permStatus.receive !== 'granted') {
					throw new Error('User denied permissions!');
				}

				await PushNotifications.register();
			}
		};

		registerPush();
	}, []);

	if (Capacitor.isNativePlatform()) {
		Preferences.get({ key: 'userEmail' }).then((emailData) => {
			if (emailData.value != null)
				setBmEmail(emailData.value);
		});
	}

	const handleBiometricAuth = async () => {
		try {
			console.log('BiometricAuth.isAvailable called');
			const bmIsAvailable = await NativeBiometric.isAvailable();
			if(!bmIsAvailable.isAvailable){
				console.log('BiometricAuth.isAvailable not available');
				setMessage('Biometric authentication not available on your device');
				setIserror(true);
				return
			}
			//const isFaceID = bmIsAvailable.biometryType == BiometryType.FACE_ID;
			console.log('BiometricAuth.verify called');
			const result = await NativeBiometric.verifyIdentity({
				reason: "For easy log in",
				title: "Log In",
				subtitle: "Biometric Authentication",
				description: "Facial or Fingerprint Authentication",
			})
			.then(() => true)
			.catch(() => false);

			if (result) {
				console.log('BiometricAuth.verify result:', result);
				if (bmEmail != null)
					setEmail(bmEmail);
				let userPassword = Preferences.get({ key: 'userPassword' });
				// Await the promise resolution
				let userPasswordData = await userPassword;
				if (userPasswordData.value != null)
					setPassword(userPasswordData.value);
				// Trigger login after setting email and password
                setTriggerLogin(true);
			} else {
				console.log('BiometricAuth.verify failed');
				setMessage('Authentication failed!');
				setIserror(true);
			}
		} catch (error: any) {
			setMessage('Error during authentication: ' + error.message);
			setIserror(true);
		}
	};

	useEffect(() => {
        if (triggerLogin && email && password) {
            handleLogin();
            setTriggerLogin(false); // Reset the trigger
        }
    }, [triggerLogin]);

	const handleLogin = async () => {
		if (!email) {
			setMessage("Please enter a valid email");
			setIserror(true);
			return;
		}
		if (validateEmail(email) === false) {
			setMessage("Your email is invalid");
			setIserror(true);
			return;
		}

		if (!password || password.length < 6) {
			setMessage("Please enter your password");
			setIserror(true);
			return;
		}

		const loginData = {
			"email": email,
			"password": password,
			"captchaToken": null,
			"captchaAction": '',
			"device_fcm_token": pushRegToken
		}

		spinnerPresent(spinnerOptions);

		/*await grecaptcha.enterprise.ready(async () => {
		  const captchaToken = await grecaptcha.enterprise.execute('6Lf2DOopAAAAAKrW9hrL8_oxVXQrhxm3cYIbYrgd', {action: "LOGIN"});
		  loginData.captchaToken = captchaToken;
		});*/

		await getCaptchaToken('LOGIN').then((token: any) => { // Here wait token generated
			if (token) {
				loginData.captchaToken = token;
				loginData.captchaAction = 'LOGIN';
			}
		});

		axiosCall("/api/login", 'post', loginData, false, 'application/json')
			.then((res: any) => {
				spinnerDismiss();
				Preferences.set({
					key: 'accessToken',
					value: res.data.access_token,
				});
				if (Capacitor.isNativePlatform()) {
					Preferences.set({
						key: 'userEmail',
						value: email
					});
					Preferences.set({
						key: 'userPassword',
						value: password
					});
				}
				//fetchUser();
				//history.push("/agentmap");
				//history.goBack();
				window.open("/my-products", "_self");
			})
			.catch((error: any) => {
				setMessage("Failed to authenticate! " + error.message);
				setIserror(true);
				spinnerDismiss();
			});
	};

	const handleRegistration = async () => {

		if (!email) {
			setMessage("Please enter a valid email");
			setIserror(true);
			return;
		}
		if (validateEmail(email) === false) {
			setMessage("Your email is invalid");
			setIserror(true);
			return;
		}
		if (!phone) {
			setMessage("Please enter a valid phone");
			setIserror(true);
			return;
		}
		if (!tNcAgreeRef) {
			setMessage("Please agree to our terms and conditions by clicking the check box.");
			setIserror(true);
			return;
		}

		const regData = {
			"name": name,
			"email": email,
			"phone": phone,
			"captchaToken": null,
			"captchaAction": ''
		}

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		await getCaptchaToken('SIGNUP').then((token: any) => { // Here wait token generated
			if (token) {
				regData.captchaToken = token;
				regData.captchaAction = 'SIGNUP';
			}
		});

		axiosCall("/api/register", 'post', regData, true)
			.then((res: any) => {
				spinnerDismiss();
				setMode("/login");
				history.push("/login");
				setMessage("Please check your email for a link. ");
			})
			.catch((error: any) => {
				setMessage(error.message);
				setIserror(true);
				spinnerDismiss();
			});

	};

	const handlePasswordReset = async () => {

		if (!email) {
			setMessage("Please enter a valid email");
			setIserror(true);
			return;
		}

		if (validateEmail(email) === false) {
			setMessage("Your email is invalid");
			setIserror(true);
			return;
		}
		const resetData = {
			"email": email,
			"captchaToken": null,
			"captchaAction": ''
		}

		await getCaptchaToken('RESET').then((token: any) => { // Here wait token generated
			if (token) {
				resetData.captchaToken = token;
				resetData.captchaAction = 'RESET';
			}
		});

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		axiosCall("/api/resetPassword", 'post', resetData, true)
			.then((res: any) => {
				spinnerDismiss();
				setMessage("Password Reset Email sent. Please check your email.");
			})
			.catch((error: any) => {
				setMessage("Reset request failed. " + error.message);
				setIserror(true);
				spinnerDismiss();
			});
	};

	const handleVerification = () => {

		if (!password || password.length < 6) {
			setMessage("Please enter your password");
			setIserror(true);
			return;
		}

		if (password != re_password) {
			return;
		}

		const regData = {
			"password": password,
			"re_password": password,
			"code": code
		}

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		axiosCall("/api/verifyEmail", 'post', regData, true)
			.then((res: any) => {
				spinnerDismiss();
				setMessage("Password successfully set. You can now login.");
				setMode('/login')
				history.push("/login");
			})
			.catch((error: any) => {
				setMessage("Unable to verify account. " + error.message);
				setIserror(true);
				spinnerDismiss();
			});

	};

	const [showPassword, setShowPassword] = useState(false);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Login</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding ion-text-center">
				<IonGrid>
					<IonRow>
						{mode == '/register' ?
							<IonCol size-md="6" offset-md="3">
								<IonRow>
									<IonCol>
										<IonLabel color="danger">{message}</IonLabel>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<IonIcon
											color="drukre"
											style={{ fontSize: "70px" }}
											icon={personCircle}
										/>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol>
										<IonItem>
											<IonLabel position="stacked"> Name </IonLabel>
											<IonInput type="text" value={name} onIonChange={(e) => setName(e.detail.value!)}>
											</IonInput>
										</IonItem>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol>
										<IonItem>
											<IonLabel position="stacked"> Mobile Number</IonLabel>
											<IonInput type="number" value={phone} onIonChange={(e) => setPhone(e.detail.value!)}>
											</IonInput>
										</IonItem>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol>
										<IonItem>
											<IonLabel position="stacked"> Email</IonLabel>
											<IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)} >
											</IonInput>
										</IonItem>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol>
										<IonCheckbox onIonChange={(e) => setTNcAgreeRef(e.target.checked)} labelPlacement="end" style={{ fontSize: "small" }}>
											<div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
												Please read our <span style={{ fontSize: "medium", color: "#0054e9" }} onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions here</span> and agree by clicking the checkbox.
											</div>
										</IonCheckbox>
										<IonButton color="drukre" expand="block" onClick={handleRegistration}>Register</IonButton>
										<p style={{ fontSize: "medium" }}>
											Already have an account? <a onClick={() => clickMode('/login')}>Sign In!</a>
										</p>
									</IonCol>
								</IonRow>
							</IonCol>
							: mode == '/resetpassword' ?
								<IonCol size-md="6" offset-md="3">
									<IonRow>
										<IonCol>
											<IonLabel color="danger">{message}</IonLabel>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonIcon
												color="drukre"
												style={{ fontSize: "70px" }}
												icon={personCircle}
											/>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonItem>
												<IonLabel position="stacked"> Email</IonLabel>
												<IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)}>
												</IonInput>
											</IonItem>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonButton color="drukre" expand="block" onClick={handlePasswordReset}>Reset Password</IonButton>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<p style={{ fontSize: "medium" }}>
												Don't have an account? <a onClick={() => clickMode('/register')}>Sign up!</a> |
												Go to <a onClick={() => clickMode('/login')}>Sign In!</a>
											</p>
										</IonCol>
									</IonRow>
								</IonCol>
								: mode.indexOf('/verifyemail') > -1 ?
									<IonCol size-md="6" offset-md="3">
										<IonRow>
											<IonCol>
												<IonLabel color="danger">{message}</IonLabel>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonIcon
													color="drukre"
													style={{ fontSize: "70px" }}
													icon={personCircle}
												/>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<IonItem>
													<IonLabel position="stacked"> Password</IonLabel>
													<IonInput type="password" onIonChange={(e) => setPassword(e.detail.value!)} >
													</IonInput>
												</IonItem>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<IonItem>
													<IonLabel position="stacked"> Re Enter Password</IonLabel>
													<IonInput type="password" onIonChange={(e) => setRePassword(e.detail.value!)} >
													</IonInput>
													{re_password != password && <p style={{ fontSize: "small", color: "red", textAlign: "center" }}>Passwords did not match!</p>}
												</IonItem>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<p style={{ fontSize: "small" }}>
													By clicking REGISTER you agree to our <span onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</span>
												</p>
												<IonButton color="drukre" expand="block" onClick={handleVerification}>Verify</IonButton>
												<p style={{ fontSize: "medium" }}>
													Already have an account? <a onClick={() => clickMode('/login')}>Sign In!</a>
												</p>

											</IonCol>
										</IonRow>
									</IonCol>
									: !authValues.authenticated ?
										<IonCol size-md="6" offset-md="3">
											<IonRow>
												<IonCol>
													<IonLabel color="danger">{message}</IonLabel>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<IonIcon
														color="drukre"
														style={{ fontSize: "70px" }}
														icon={personCircle}
													/>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<IonItem>
														<IonLabel position="stacked"> Email</IonLabel>
														<IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)}>
														</IonInput>
													</IonItem>
												</IonCol>
											</IonRow>

											<IonRow>
												<IonCol>
													<IonItem lines="none" style={{ display: "flex", alignItems: "center" }}>
														<IonLabel position="stacked"> Password</IonLabel>
														<IonInput
															type={showPassword ? "text" : "password"}
															value={password}
															onIonChange={(e) => setPassword(e.detail.value!)}
														/>
														<IonIcon
															slot="end"
															icon={showPassword ? eyeOff : eye}
															onClick={() => setShowPassword(!showPassword)}
														/>
													</IonItem>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<p style={{ fontSize: "small" }}>
														By clicking LOGIN you agree to our <span onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</span>
													</p>
													<IonButton color="drukre" expand="block" onClick={handleLogin}>Login</IonButton>
													<p style={{ fontSize: "medium" }}>
														Don't have an account? <a onClick={() => clickMode('/register')}>Sign up!</a> |
														Forgot Password? <a onClick={() => clickMode('/resetpassword')}>Reset!</a>
													</p>
													{Capacitor.isNativePlatform() && bmEmail != null &&
														<IonButton color="drukre" onClick={handleBiometricAuth}><IonIcon icon={fingerPrint}></IonIcon>Facial/Fingerprint Login</IonButton>
													}
												</IonCol>
											</IonRow>
										</IonCol>
										:
										<IonCol size-md="6" offset-md="3">
											<UserStatus />
										</IonCol>
						}
					</IonRow>
					<IonRow>
						<IonCol>
							<p style={{ fontSize: "small" }}>
								This site is protected by reCAPTCHA and the Google
								<a href="https://policies.google.com/privacy" target="_blank"> Privacy Policy</a> and
								<a href="https://policies.google.com/terms" target="_blank"> Terms of Service</a> apply.
							</p>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default Auth;