import {
    IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonContent,
    IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar, IonImg, useIonModal,
    IonItem, IonLabel, useIonToast, IonSpinner, IonNote, IonAvatar, IonBackButton
} from "@ionic/react";
import { arrowRedoOutline, cart, cartOutline, chevronBackOutline, heart, heartOutline, alertSharp, 
    bagCheckOutline, bookmarkOutline, bookmark, starOutline, star, 
    eyeSharp} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

import axiosCall from '../data/axios';

import { getCaptchaToken } from "../Helper";

import { SwiperZoomModal } from '../components/SwiperZoomModal';

import ProductCard from "../components/ProductCard";
import { addToCart, CartStore } from "../data/CartStore";
import { addToFavourites, FavouritesStore } from "../data/FavouritesStore";
import { ProductStore } from "../data/ProductStore";

import styles from "./Product.module.css";

import { coreLocations } from '../data';
import { format } from "date-fns";

const SellerReviewModal = ({ reviews } ) => {
    const maxRating = 5;
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Seller Reviews</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent style={{ overflowY: 'auto', maxHeight: '100vh' }}>
                <IonGrid className="ion-padding">
                {reviews.map((review, idx) => {
                    let invImages = review.inventory_images?.length > 0 ? JSON.parse(review.inventory_images) : ['/assets/no_image_available.jpg'];
                    return ( 
                        <IonRow key={idx} className="ion-padding">
                            <IonCol>
                                <IonRow>{review.customer_review}</IonRow>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '10px', marginTop: '10px'  }}>
                                {[...Array(maxRating)].map((_, index) => (
                                    <IonItem key={index} lines="none" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '20%' }}>
                                        <IonIcon
                                            icon={parseInt(review.customer_rating) > index ? star : starOutline}
                                            style={{ fontSize: '24px', cursor: 'pointer' }}
                                        />
                                    </IonItem>
                                ))}
                                </div>
                                <IonItem>
                                    <a href={`/category/${review.category_name}/${review.inventory_id}`} title="Go to item">
                                        <IonAvatar>
                                            <IonImg src={`${process.env.REACT_APP_PRODUCT_ASSETS}/${invImages[0]}`} />
                                        </IonAvatar>
                                    </a>
                                    <IonNote>{review.inventory_name}</IonNote>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    )
                })}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

const Product = () => {

    const history = useHistory();

    const params = useParams();
    const cartRef = useRef();
    const productCartRef = useRef();
    const productFavouriteRef = useRef();
    const products = ProductStore.useState(s => s.products);
    const favourites = FavouritesStore.useState(s => s.product_ids);
    const [isFavourite, setIsFavourite] = useState(false);
    const shopCart = CartStore.useState(s => s.product_ids);
    const [product, setProduct] = useState({});
    const [category, setCategory] = useState({});
    const [images, setImages] = useState([]);
    const [presentToast] = useIonToast();
    const [sellerDetails, setSellerDetails] = useState({});

    useEffect(() => {
        //const paths = window.location.pathname.split('/');
        if (products.length < 1)
            return;

        const categorySlug = params.slug;
        const productID = params.id;
        const tempCategory = products.filter(p => p.slug === categorySlug)[0];

        if (!tempCategory)
            return;

        const tempProduct = tempCategory.products.filter(p => parseInt(p.id) === parseInt(productID))[0];

        if (!tempProduct)
            return;

        const tempIsFavourite = favourites.find(f => f === `${categorySlug}/${productID}`);

        setIsFavourite(tempIsFavourite);
        setCategory(tempCategory);
        setProduct(tempProduct);
        setImages(tempProduct.images);
    }, [params.id]);

    useEffect(() => {

        const tempIsFavourite = favourites.find(f => f === `${category.slug}/${product.id}`);
        setIsFavourite(tempIsFavourite ? true : false);
    }, [favourites, product]);

    const addProductToFavourites = (e, categorySlug, productID) => {

        e.preventDefault();
        addToFavourites(categorySlug, productID);

        productFavouriteRef.current.style.display = "";
        productFavouriteRef.current.classList.add("animate__fadeOutTopRight");

        setTimeout(() => {
            if (productCartRef.current) {
                productFavouriteRef.current.classList.remove("animate__fadeOutTopRight");
                productFavouriteRef.current.style.display = "none";
            }
        }, 500);
    }

    const addProductToCart = (e, categorySlug, productID) => {

        if (product.delivery_locations.length < 1) {
            presentToast({
                message: "This product does not have a delivery location.",
                icon: alertSharp,
                color: 'danger',
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ]
            });
            return;
        }

        e.preventDefault();

        productCartRef.current.style.display = "";
        productCartRef.current.classList.add("animate__fadeOutUp");

        setTimeout(() => {

            cartRef.current.classList.add("animate__tada");
            addToCart(categorySlug, productID);

            setTimeout(() => {
                cartRef.current.classList.remove("animate__tada");
                productCartRef.current.style.display = "none";
            }, 500);
        }, 500);
    }

    const zoomModalDismiss = () => {
        dismissZoomModal();
    };

    const [clickedImageKey, setClickedImageKey] = useState(0);

    const [presentZoomModal, dismissZoomModal] = useIonModal(SwiperZoomModal, { zoomModalDismiss, images, clickedImageKey });

    function slideClick(imgKey) {

        setClickedImageKey(imgKey);

        presentZoomModal({
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            backdropBreakpoint: 0,
            animated: true,
            swipToClose: true,
            cssClass: 'modal-fullscreen'
        });

    }

    function calculateSimilarity(str1, str2) {
        const commonCharacters = str1.split('').filter(char => str2.includes(char)).length;
        return commonCharacters / Math.min(str1.length, str2.length); // Simple ratio of common characters
    }

    // Define the buyNow function
    const buyNow = (e, categorySlug, productId) => {
        e.preventDefault();
        history.push(`/buynow?category=${categorySlug}&product_id=${productId}`);
    };

    //GET user rating
    const [userRating, setUserRating] = useState({
        loading: true
    });

    useEffect(() => {

        if (!product.owner) return;

        const fetchUserRating = async () => {

            var captchaData = {
                captchaToken: '',
                captchaAction: ''
            };
    
            await getCaptchaToken('SAVE').then((token) => { // Here wait token generated
                if (token) {
                    captchaData.captchaToken = token;
                    captchaData.captchaAction = 'SAVE';
                }
            });

            await axiosCall("/api/user-rating", 'get', 
                {user_id: product.owner, captchaData: captchaData}
            ).then(async(res) => {

                let avgRating = 0; let ratingCounter = 0;
                let customer_reviews = [];
                
                await res.data.rating.forEach((rating) => {

                    ratingCounter++;

                    avgRating = (avgRating + parseInt(rating.customer_rating)) / ratingCounter;

                    customer_reviews.push(rating);

                });

                setSellerDetails({name: (res.data.seller[0].name ? res.data.seller[0].name : res.data.seller[0].email)})

                setUserRating({
                    rating: avgRating,
                    rating_detail: customer_reviews, 
                    loading: false
                });

                setReviews(customer_reviews);

            })
            .catch((error) => {
                console.log(error);
            });
        };

        fetchUserRating();
        
    }, [product.owner]);

    const [ reviews, setReviews] = useState(userRating.rating_detail);
    const [presentReviews, setPresentReviews] = useIonModal(SellerReviewModal, {reviews});

    const viewReviews = () => {
        presentReviews({
            initialBreakpoint: 0.5,
            breakpoints: [0, 0.4, 0.7, 1],
            backdropBreakpoint: 0
        });
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: product.name,
                    text: `Check out this product: ${product.name}`,
                    url: window.location.href,
                });
                console.log('Product shared successfully');
            } catch (error) {
                console.error('Error sharing product:', error);
            }
        } else {
            console.log('Web Share API not supported in this browser');
        }
    };

    return (

        <IonPage id="category-page" className={styles.categoryPage}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" text={category.name} routerLink={`/category/${category.slug}`} routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;{category.name}
                        </IonButton>
                    </IonButtons>

                    <IonTitle></IonTitle>

                    <IonButtons slot="end">
                        <IonBadge color="dark">
                            {shopCart.length}
                        </IonBadge>
                        <IonButton color="dark" routerLink="/cart">
                            <IonIcon ref={cartRef} className="animate__animated" icon={cart} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="Go Back" defaultHref="/" routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Go Back
                        </IonBackButton>
                        <IonButton color="dark" text={category.name} routerLink={`/category/${category.slug}`}>
                            <IonIcon color="dark" />&nbsp;Go to {category.name}
                        </IonButton>
                    </IonButtons>

                    <IonButtons slot="end" class="ion-padding-left">
                        <IonButton color="drukre" routerLink="/cart">
                            {shopCart.length}
                            <IonIcon ref={cartRef} className="animate__animated" icon={cartOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <IonCard>
                                <IonCardHeader className="ion-text-center">
                                    <div className={styles.productCardActions}>
                                        <IonIcon className={styles.productCardAction} color={isFavourite ? "danger" : "medium"} icon={isFavourite ? bookmark : bookmarkOutline} onClick={e => addProductToFavourites(e, category.slug, product.id)} />
                                        <IonIcon ref={productFavouriteRef} style={{ position: "absolute", display: "none" }} id={`placeholder_favourite_product_${category.slug}_${product.id}`} className={`${styles.productCardAction} animate__animated`} color="danger" icon={bookmark} />
                                        <IonIcon className={styles.productCardAction} size="medium" icon={arrowRedoOutline} onClick={handleShare} />
                                    </div>
                                    <p className="ion-text-wrap">{product.name}</p>
                                </IonCardHeader>

                                <IonCardContent>
                                    <div className="ion-text-center">
                                        {product.images ?
                                            <Swiper slidesPerView={1} initialSlide={2} speed={400}
                                                spaceBetween={10} centeredSlides={true} zoom={true}
                                                key={product.images.map(slide => slide.id).join('_')}
                                                breakpoints={{
                                                    // when window width is >= 640px
                                                    640: {
                                                        slidesPerView: 3,
                                                    },
                                                    // when window width is >= 768px
                                                    768: {
                                                        slidesPerView: 4,
                                                    },
                                                    // when window width is >= 1024px
                                                    1024: {
                                                        slidesPerView: 5,
                                                    },
                                                }}>
                                                {product.images.map((image, i) => {
                                                    //if(!image.src.includes('undefined')) { 
                                                    return <SwiperSlide key={i}>
                                                        <div className="swiper-zoom-container">
                                                            <IonImg src={`${process.env.REACT_APP_PRODUCT_ASSETS}/${image}`} onClick={() => slideClick(i)} />
                                                        </div>
                                                    </SwiperSlide>
                                                    //}
                                                }
                                                )}
                                            </Swiper>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {product.description}
                                    </div>
                                    {(product.condition && product.condition != 'null') ? 
                                        <IonItem>CONDITION: {product.condition.toUpperCase()}</IonItem> 
                                    : null}
                                    <div>
                                        <IonItem>Pickup/Delivery Locations: </IonItem>
                                        {(product.delivery_locations && product.delivery_locations.length > 0) ?
                                            <div>
                                                {product.delivery_locations.map((delivery_loc, i) => {
                                                    let data = JSON.parse(delivery_loc.data);

                                                    if (delivery_loc.charges > 0) {
                                                        const locationsArray = JSON.parse(delivery_loc.location); // Parse the JSON string to an array
                                                        return locationsArray.map((coreLocKey, i) => {
                                                            const label = Object.entries(coreLocations).find(([key, value]) => key === coreLocKey)?.[1];
                                                            return <p key={i} className="ion-padding-start"> - {label} - Within {data?.days} day(s) - Nu. {delivery_loc.charges}</p>;
                                                        });
                                                    } else
                                                        return <p key={i} className="ion-padding-start"> - {delivery_loc.location} - Within {data?.days} day(s) - Free</p>;
                                                })}
                                            </div>
                                            : <IonLabel className="ion-padding-start"> - No pickup locations</IonLabel>
                                        }
                                    </div>
                                    <IonRow className="ion-align-items-center ion-hide-md-down">
                                        <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" className="ion-justify-center">
                                            <div className={styles.productPrice} style={{ display: "flex", justifyContent: "space-between" }} >
                                                <IonButton fill="outline" color="drukre">
                                                    {product.price}
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                        <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" className="ion-align-self-center">
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <IonButton
                                                    size="large"
                                                    color="drukre"
                                                    onClick={e => buyNow(e, category.slug, product.id)}
                                                    disabled={product.stock < 1}
                                                    className="ion-self-center"
                                                >
                                                    <IonIcon icon={bagCheckOutline} />&nbsp;&nbsp;Buy Now
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                        <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" className="ion-align-self-center">
                                            <div className={styles.productPrice} style={{ display: "flex", justifyContent: "flex-end" }} >
                                                <IonButton color="drukre" fill="outline"
                                                    onClick={e => addProductToCart(e, category.slug, product.id)}
                                                    disabled={product.stock < 1}>
                                                    <IonIcon icon={cartOutline} />&nbsp;&nbsp;{product.stock < 1 ? 'Out of Stock' : 'Add to Cart'}
                                                </IonButton>
                                                <IonIcon ref={productCartRef} icon={cart} color="dark" style={{ position: "absolute", display: "none", fontSize: "3rem" }} id={`placeholder_cart_${category.slug}_${product.id}`} className="animate__animated" />
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center ion-hide-md-up">
                                        <IonCol size="4" className="ion-justify-center">
                                            <div className={styles.productPrice} style={{ display: "flex", justifyContent: "space-between" }} >
                                                <IonButton fill="outline" color="drukre">
                                                    {product.price}
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                        <IonCol size="8" className="ion-align-self-center">
                                            <div className={styles.productPrice} style={{ display: "flex", justifyContent: "flex-end" }} >
                                                <IonButton color="drukre" fill="outline"
                                                    onClick={e => addProductToCart(e, category.slug, product.id)}
                                                    disabled={product.stock < 1}>
                                                    <IonIcon icon={cartOutline} />&nbsp;&nbsp;{product.stock < 1 ? 'Out of Stock' : 'Add to Cart'}
                                                </IonButton>
                                                <IonIcon ref={productCartRef} icon={cart} color="dark" style={{ position: "absolute", display: "none", fontSize: "3rem" }} id={`placeholder_cart_${category.slug}_${product.id}`} className="animate__animated" />
                                            </div>
                                        </IonCol>
                                        <IonCol size="12" className="ion-align-self-center">
                                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                                <IonButton
                                                    size="large"
                                                    color="drukre"
                                                    onClick={e => buyNow(e, category.slug, product.id)}
                                                    disabled={product.stock < 1}
                                                    className="ion-self-center"
                                                    style={{ width: "100%" }}
                                                >
                                                    <IonIcon icon={bagCheckOutline} />&nbsp;&nbsp;Buy Now
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem>Seller Details</IonItem>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                
                                                {userRating.loading ? <IonSpinner name="dots"></IonSpinner> : 
                                                    <>
                                                    <IonNote>Seller : </IonNote> {sellerDetails.name}
                                                    <IonRow>
                                                    {[...Array(5)].map((_, index) => (
                                                        <IonItem key={index} lines="none" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '20%' }}>
                                                            <IonIcon
                                                                icon={userRating.rating > index ? star : starOutline}
                                                                style={{ fontSize: '24px', cursor: 'pointer' }}
                                                            />
                                                        </IonItem>
                                                    ))}
                                                    </IonRow>
                                                    {userRating.rating > 0 ?
                                                        <IonButton color="primary" onClick={() => viewReviews()}>
                                                            <IonIcon icon={eyeSharp} slot="start" />
                                                            View Reviews
                                                        </IonButton>
                                                        :
                                                        <IonNote>No Rewiews & Ratings</IonNote>
                                                    }
                                                    </>
                                                }
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>

                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-text-center">
                        <IonCol size="12">
                            <IonCardSubtitle>Similar products...</IonCardSubtitle>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        {(category && category.products) && category.products.map((similar, index) => {

                            const similarityThreshold = 0.5; // Define how similar names need to be (0 to 1)
                            const similarity = calculateSimilarity(similar.name.toLowerCase(), product.name.toLowerCase());


                            if (similar.id != product.id && (similarity >= similarityThreshold) && product.images && index < 4) {
                                return (
                                    <ProductCard key={`similar_product_${index}`} product={similar} index={index} isFavourite={false} cartRef={cartRef} category={category} />
                                );
                            }
                        })}
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Product;