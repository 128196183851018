import { useState, useContext, useRef, useEffect } from 'react';
import { Link, Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import {
	IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardSubtitle, IonCol, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton,
	IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar, IonSpinner,
	IonItem, IonSearchbar,IonList, IonFabButton, IonFab, IonLabel, IonRefresher, IonRefresherContent
} from '@ionic/react';

import {
	IonReactRouter
} from '@ionic/react-router';
import styles from "./Home.module.css";
import { cartOutline, heartOutline, bagCheck, search, bookmarkOutline } from 'ionicons/icons';

import { FeaturedStore } from '../data/FeaturedStore';

import { ProductStore } from '../data/ProductStore';
import { FavouritesStore } from '../data/FavouritesStore';
import { CartStore } from '../data/CartStore';
import { CategoryStore } from '../data/CategoryStore';
import FavouriteProducts from './FavouriteProducts';
import CartProducts from './CartProducts';
import ProductCard from "../components/ProductCard";

import { fetchFeatured } from '../data/fetcher';

import AuthContext from '../UserContext';
import Contact from '../components/Contact';

const Home = () => {

	const history = useHistory();
	const location = useLocation();

	const { authValues } = useContext(AuthContext);

	const products = ProductStore.useState(s => s.products);
	const favourites = FavouritesStore.useState(s => s.product_ids);
	const shopCart = CartStore.useState(s => s.product_ids);

	const cartRef = useRef();
	const searchInputRef = useRef();

	const categoryHasLoaded = CategoryStore.useState(s => s.hasLoaded);
	const productHasLoaded = ProductStore.useState(s => s.hasLoaded);

	const featuredProducts = FeaturedStore.useState(s => s.products);
	const featuredHasLoaded = FeaturedStore.useState(s => s.hasLoaded);
	const featuredPagination = FeaturedStore.useState(s => s.pagination);
	//LOAD MORE FEATURED PRODUCTS
	const [page, setPage] = useState(featuredPagination.current_page);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);

	useEffect(() => {

		if((featuredPagination.current_page < featuredPagination.last_page))
			setHasMore(true);
		else 
			setHasMore(false);

		setPage(featuredPagination.current_page);

	}, [featuredPagination.current_page]);

	// Function to fetch next set of products
	const fetchMoreProducts = async () => {
		setLoading(true);
		const response = await fetchFeatured(page+1); // Your API call to fetch products, passing the current page
		setLoading(false);
	};

	// Load More button click handler
	const handleLoadMore = () => {
		if (!loading) {
			fetchMoreProducts();
		}
	};
	//END FEATURED PRODUCTS

	let [searchResults, setSearchResults] = useState([]);
	
	if(!productHasLoaded || !featuredHasLoaded || !categoryHasLoaded)
		return <IonSpinner name="lines-sharp" color="drukre" style={{display: 'flex',  marginLeft: 'auto', marginRight: 'auto', justifyContent:'center', alignItems:'center', height: '100vh'}}></IonSpinner>;

	const handleSearchInput = (ev) => {

		setSearchResults([]); 
		
		let query = '';
		const target = ev.target;
		if (target) query = target.value.toLowerCase();
		
		if(query.length < 3) return;
		Object.entries(products).filter(([idx, category]) => {
			category.products.filter((prod) =>{
				if(prod.description.toLowerCase().indexOf(query) > -1 || prod.name.toLowerCase().indexOf(query) > -1) {
					setSearchResults(prev => [...prev, prod.name]);
				}
			});
		});
		
	};
	
	const searchListClick = (ev, data = null) => {

		if(data === null)
			data = searchInputRef.current.value;
		console.log(data);

		history.push("/search", {data: data});  
		/*
		setLocation(gew);
		setLocationResults([]);
		*/
	};

	const searchKeyDown = (ev) => {
		if (ev.key === 'Enter') {
			searchListClick(ev);
		}
	};

	function handleRefresh(event) {
		setTimeout(() => {
		  // Any calls to load data go here
		  featuredProducts = [];
		  fetchFeatured();
		  event.detail.complete();
		}, 2000);
	  }

	return (
		<IonPage id="home-page" className={styles.homePage}>
			<IonHeader>
				<IonToolbar>

					<IonButtons slot="start" className="ion-padding-start" color="drukre">
						<IonCardSubtitle className={styles.logo}></IonCardSubtitle>
					</IonButtons>
				</IonToolbar>
				<IonToolbar>
					<IonRow>
						<IonCol sizeLg="9" sizeMd="9" sizeSm="10" sizeXs="10" className="ion-align-self-center">
							{/*
							<IonItem>
								<IonIcon icon={locationOutline} color="drukre" />
								<IonInput label="Location" value={location} onIonChange={e => setLocation(e.target.value)}></IonInput>
							</IonItem>
							*/}
							<IonSearchbar onIonClear={(e) => setSearchResults([])} debounce={1000} label="Search" ref={ searchInputRef }
								placeholder="Looking for ..." name="search" onIonInput={(ev) =>handleSearchInput(ev)} onKeyDown={(ev) => searchKeyDown(ev)}>
							</IonSearchbar>
							<IonList className="ion-padding-horizontal">
								{/*searchResults && Object.entries(searchResults).map(([dzo, gew]) => (
									<IonItem key={gew} onClick={(ev) => searchListClick(ev, gew)}>{gew}</IonItem>
								))*/}
								{ searchResults && searchResults.map((product, index) => {
									const trimmedProduct = product.length > 20 ? product.substring(0, 20) : product;
									return (
										<IonItem key={ `prod_${ index }`} index={ index } onClick={(ev) => searchListClick(ev, trimmedProduct)}>{product.length > 20 ? trimmedProduct + ' ...': trimmedProduct}</IonItem>
									);
								})}
							</IonList>
						</IonCol>
						<IonCol sizeSm="2" sizeXs="2" className="ion-hide-md-up">
							<IonButton size="small" color="drukre" onClick={(ev) => searchListClick(ev)}>
								<IonIcon icon={search} />
							</IonButton>
						</IonCol> 
						<IonCol sizeLg="1" sizeMd="1" className="ion-hide-md-down">
							<IonButton color="drukre" onClick={(ev) => searchListClick(ev)}>
								<IonIcon icon={search} />
							</IonButton>
						</IonCol>
						<IonCol sizeLg="2" sizeMd="2" sizeSm="12" sizeXs="12" className="ion-align-self-center">
							<IonButtons className="ion-padding-end ion-float-end">
								<IonButton color="danger" routerLink="/favourites">
									{favourites.length}
									<IonIcon icon={bookmarkOutline} />
								</IonButton>

								<IonButton color="drukre" routerLink="/cart">
									{shopCart.length}
									<IonIcon ref={ cartRef } className="animate__animated" icon={ cartOutline } />
								</IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>

				{/*
				<IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
					<IonRefresherContent refreshingSpinner="circles" color="drukre"></IonRefresherContent>
				</IonRefresher>
				{/*
				<IonToolbar>
					<IonReactRouter>
						<IonTabs>
							<IonRouterOutlet>
							<Route path="/favourites" exact>
								<FavouriteProducts />
							</Route>

							<Route path="/cart" exact>
								<CartProducts />
							</Route>
							</IonRouterOutlet>
							<IonTabBar slot="bottom" className={ styles.cartBadgeTabBar }>
								<IonTabButton tab="favourites" routerLink="/favourites">
									<IonIcon color="drukre" icon={heartOutline} />
									<IonBadge color="danger">{ favourites.length }</IonBadge>
								</IonTabButton>
								<IonTabButton tab="cart" href="/cart">
									<IonIcon color="drukre" icon={cartOutline} />
									<IonBadge color="danger">{ shopCart.length }</IonBadge>
								</IonTabButton>
							</IonTabBar>
							
						</IonTabs>
		 			</IonReactRouter>
				</IonToolbar>
			*/}
				<IonGrid>
					
					{!location.pathname.includes('/categories') && (
						<>
							<IonRow>
								<IonCol>
									<IonCardSubtitle className='ion-padding-horizontal'>Featured Products</IonCardSubtitle>
								</IonCol>
							</IonRow>
							<IonRow>
								{featuredProducts.map((prod, index) => {
									let category = {slug: prod.category_name};
									return (
										<ProductCard key={`category_product_${index}`} product={prod} index={index} cartRef={cartRef} category={category} />
									);
								})}
							</IonRow>
							<IonRow>
								<IonCol>
									{hasMore && (
										<IonButton className="ion-float-right" color="drukre" onClick={handleLoadMore} disabled={loading}>
											{loading ? 'Loading...' : 'Load More'}
										</IonButton>
									)}
								</IonCol>
							</IonRow>
						</>
					
					)}
					<IonRow>
						<IonCol>
							<IonCardSubtitle className='ion-padding-horizontal'>Categories</IonCardSubtitle>
						</IonCol>
					</IonRow>

					<IonRow>
						{products.map((category, index) => {

							return (
								<IonCol sizeXs="6" sizeSm="6" sizeMd="3" key={`category_list_${index}`} style={{display: "flex"}}>
									<IonCard routerLink={`/category/${category.slug}`} className={styles.categoryCard}>

										<img src={`${process.env.REACT_APP_BASE_URL}/images/categories/${category.cover}`} alt="category cover" />

										<IonCardContent color="drukre" className={styles.categoryCardContent}>
											<IonCardSubtitle>{category.name}</IonCardSubtitle>
										</IonCardContent>
									</IonCard>
								</IonCol>
							)
						})}
					</IonRow>
				</IonGrid>
				
				<IonFab horizontal="end" vertical="bottom" slot="fixed">
					<IonFabButton color="drukre" href={`${authValues.authenticated ? "/my-products" : "/login"}`}>
						<IonIcon icon={bagCheck}></IonIcon><sub>Sell</sub>
					</IonFabButton>
				</IonFab>
				<IonRow>
					<IonCol  className="ion-text-center">
						<span onClick={() => history.push("/terms-and-conditions")}>
							<IonLabel>
								Terms and Conditions | Privacy Policy | &nbsp;
							</IonLabel>
						</span>
						<Contact />
						<p style={{ fontSize: "small" }}>
							This application is protected by reCAPTCHA and the Google 
							<a href="https://policies.google.com/privacy" target="_blank"> Privacy Policy</a> and 
							<a href="https://policies.google.com/terms" target="_blank"> Terms of Service</a> apply.
						</p>
					</IonCol>
					</IonRow>
			</IonContent>
		</IonPage>
	);
};

export default Home;