import {
	IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonNote, IonRow, IonThumbnail, IonImg,
	IonSelect,
	IonSelectOption, IonList,
	IonItem,
	IonInput,
	IonFooter,
	IonPage,
	IonTitle,
	IonHeader,
	IonToolbar,
	useIonModal,
	IonButtons,
	IonButton,
	useIonLoading,
	useIonToast,
	IonSlide,
	IonSlides,
	useIonAlert,
	IonSearchbar,
	IonTextarea
} from '@ionic/react';
import {
	globeOutline, heartOutline, locationOutline, navigateOutline, phonePortraitOutline, closeOutline,
	idCardOutline, cameraOutline, information, pricetagOutline, mailOutline, desktopOutline, checkmark, alertSharp,
	alertOutline,
	cloudUploadOutline
} from "ionicons/icons";
import { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

import { SwiperZoomModal } from '../components/SwiperZoomModal';

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import Resizer from 'react-image-file-resizer';

import axiosCall from '../data/axios';

import { coreLocations } from '../data';

import { CategoryStore } from '../data/CategoryStore';

import { getCaptchaToken } from "../Helper";

export const ProductEditor = ({ product, onDismiss, afterEvent }) => {

	defineCustomElements(window);

	const categories = CategoryStore.useState(s => s.categories);

	const publishStatuses = [
		{
			id: 1,
			name: "published",
			label: "Published"
		},
		{
			id: 2,
			name: "unpublished",
			label: "Unpublished"
		}
	];

	const conditions = [
		{
			id: 1,
			name: "new",
			label: "Brand New"
		},
		{
			id: 2,
			name: "used",
			label: "Used"
		}
	];

	const compareWith = (o1, o2) => {
		if (!o1 || !o2) {
			return o1 === o2;
		}

		if (Array.isArray(o2)) {
			return o2.some((o) => o.id === o1.id);
		}

		return o1 === o2;
	};

	const [presentAlert] = useIonAlert();

	const id = product?.id;

	let mProperty = product?.property_type; let mSale = product?.sale_type;

	const [category, setCategory] = useState(product?.category_id);

	const [images, setImages] = useState(product?.images ? product.images : []);

	const [label, setLabel] = useState(product?.name);

	const [info, setInfo] = useState(product?.description);

	const [location, setLocation] = useState(product?.address);

	const [price, setPrice] = useState(product?.actual_price);

	const [stock, setStock] = useState(product?.stock);

	//const [status, setStatus] = useState(product?.status);

	const [condition, setCondition] = useState(product?.condition);

	const zoomModalDismiss = () => {
		dismissZoomModal();
	};

	const [clickedImageKey, setClickedImageKey] = useState(0);

	const [presentZoomModal, dismissZoomModal] = useIonModal(SwiperZoomModal, { zoomModalDismiss, images, clickedImageKey });

	function slideClick(imgKey) {

		setClickedImageKey(imgKey);

		presentZoomModal({
			initialBreakpoint: 1,
			breakpoints: [0, 1],
			backdropBreakpoint: 0,
			animated: true,
			swipToClose: true,
			cssClass: 'modal-fullscreen'
		});

	}

	const [newImages, setNewImages] = useState([]);

	/**
	 * upload to storage bucket, convert path to blob
	 * get file name from path
	 * 
	 * https://github.com/aaronksaunders/ionic-react-supabase-simple-storage
	 * 
	 * @param path
	 */
	/** Not required for now as image blobs during save
	const imageUploadReady = async (path) => {

		const response = await fetch(path);

		const blob = await response.blob();

		const filename = path.substr(path.lastIndexOf("/") + 1);

		setNewImages(existingNewImages => [...existingNewImages, blob]);

		return true;
	};
	*/

	/**
	* take picture using capacitor plugin
	*/
	//const [imagePath, setImagePath] = useState("");

	const takePicture = async () => {

		try {
			const cameraResult = await Camera.getPhoto({
				quality: 100,
				//allowEditing: true,
				width: 500,
				resultType: CameraResultType.Uri,
				source: CameraSource.Prompt,
				promptLabelHeader: 'Choose Picture',
				promptLabelCancel: 'Cancel',
				promptLabelPhoto: 'From Photo Gallery',
				presentationStyle: 'fullscreen',
				webUseInput: true
			});

			const path = cameraResult?.webPath || cameraResult?.path;

			//setImagePath(path);

			setImages(prevImages => [...prevImages, { src: path }]);

			//await imageUploadReady(path);

			//setNewImages(existingNewImages => [...existingNewImages, path]);

			return true;

		} catch (e) {
			console.log(e);
		}
	};

	const handleFileChange = async (event) => {
		const files = event.target.files;
		const fileArray = Array.from(files);

		if (fileArray.length > 5) {
			alert(`Please upload a maximum of 5 images at a time.`);
			return;
		}

		const imagePromises = fileArray.map(async (file) => {
			if (!file.type.startsWith('image/')) {
				alert(`File ${file.name} is not an image.`);
				return null;
			}
			const reader = new FileReader();
			return new Promise((resolve) => {
				reader.onloadend = () => {
					resolve({ src: reader.result });
				};
				reader.readAsDataURL(file);
			});
		});

		const imageResults = await Promise.all(imagePromises);

		// Filter out any null results from non-image files
		const validImageResults = imageResults.filter(result => result !== null);

		// Only update the state if there are valid images
		if (validImageResults.length > 0) {
			setImages((prevImages) => [...prevImages, ...validImageResults]);
		}
	};

	const openFileDialog = () => {
		(document).getElementById("multi-image-upload").click();
	};

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				720,
				720,
				"JPEG",
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				"file"
			);
		});

	const [spinnerPresent, spinnerDismiss] = useIonLoading();
	const [presentToast] = useIonToast();

	async function save() {

		let dt = new Date();

		let data = {
			"name": label,
			"location": location,
			"description": info,
			"price": price,
			"stock": stock,
			//"status": status,
			"category_id": category,
			"condition": condition
		};

		if (price < 1) {
			presentToast({
				message: 'Price cannot be less than Nu. 1',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]

			});
			return;
		}

		if (typeof label === 'undefined' || label == '' || (label && label.length < 3)) {
			presentToast({
				message: 'Title should be atleast 3 characters long',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			});
			return;
		}

		let formData = new FormData();

		Object.entries(data).map(([key, value]) => {
			formData.append(key, value);
		});

		if (deliveryLocations[0].location == '' && paidLocations[0].location.length == 0) {

			presentToast({
				message: 'Atleast one delivery or pickup location is needed.',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			});

			return;

		}

		deliveryLocations.map((loc, i) => {
			if (loc.location == '') return;
			formData.append('delivery_locations[]', JSON.stringify(loc));
		});

		paidLocations.map((loc, i) => {
			if (loc.location.length == 0 || loc.price == '') return;
			loc.price = parseInt(loc.price, 10);
			formData.append('paid_locations[]', JSON.stringify(loc));
		});

		if (images.length == 0) {
			presentToast({
				message: 'Atleast one image is needed.',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			});
			return;
		}

		for (const img of images) {
			/**
			 * Fetches from both local for new images and from public folder on backend server
			 * To be able to fetch from backend server, this had to be done on .htaccess in public folder
			 * Header always set Access-Control-Allow-Origin "*"
			 * Might have to move the image storage to some other location in the backend.
			 * Also need to delete existing image from the file location.
			 */
			const response = await fetch(img.src);
			const blob = await response.blob();
			let imgFile = new File([blob], Math.random() + '.' + blob?.type.split('/')[1], { 'lastModifiedDate': new Date(), 'type': blob?.type });
			let resizedImg = await resizeFile(imgFile);
			formData.append("images[]", resizedImg);
		}

		if (id) {
			formData.append('id', id);
		}

		var captchaData = {};

		await getCaptchaToken('SAVE').then((token) => { // Here wait token generated
			if (token) {
				formData.append('captchaToken', token);
				formData.append('captchaAction', 'SAVE');
			}
		});

		for (var pair of formData.entries()) {
			console.log(pair[0] + ', ' + pair[1]);
		}

		spinnerPresent({
			message: 'Saving. Please wait...',
			spinner: 'bubbles',
			animated: true,
			cssClass: 'drukre-loading'
		});

		axiosCall("/api/saveProduct", "post", formData, true, 'multipart/form-data')
			.then((res) => {
				//setMyMarkersCheck(false);
				spinnerDismiss();
				presentToast({
					message: 'Product submitted for admin verification. Check your pending tab to see unpublished listings.',
					icon: checkmark,
					color: 'success',
					buttons: [
						{
							text: 'Dismiss',
							role: 'cancel'
						}
					]
				});

				afterEvent();
			})
			.catch((error) => {
				//setMessage("Auth failure! Please create an account");
				//setIserror(true);
				spinnerDismiss();
				presentToast({
					message: error.message,
					icon: alertSharp,
					color: 'danger',
					buttons: [
						{
							text: 'Dismiss',
							role: 'cancel'
						}
					]
				});
			});

	}

	function deleteImage(e, imgKey) {

		let updatedImages = images.filter((item, i) => imgKey !== i);

		//const slides = document.querySelectorAll('ion-slide');

		if (updatedImages.length > 0) {

			setImages(updatedImages);

		} else {

			presentToast({
				message: 'Keep atleast one image',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			});

		}

	}

	function archiveProduct(confirm = false) {

		spinnerPresent({
			message: 'Deleting. Please wait...',
			spinner: 'bubbles',
			animated: true,
			cssClass: 'drukre-loading'
		});

		axiosCall("/api/product/archive", "post", { product_id: product.id }, true, 'multipart/form-data')
			.then((res) => {
				//setMyMarkersCheck(false);
				spinnerDismiss();
				presentToast({
					message: 'Product Archived Successfully!',
					icon: checkmark,
					color: 'success',
					buttons: [
						{
							text: 'Dismiss',
							role: 'cancel'
						}
					]
				});

				onDismiss();
			})
			.catch((error) => {
				//setMessage("Auth failure! Please create an account");
				//setIserror(true);
				spinnerDismiss();
				presentToast({
					message: error.message,
					icon: alertSharp,
					color: 'danger',
					buttons: [
						{
							text: 'Dismiss',
							role: 'cancel'
						}
					]
				});
			});

	}

	var existingLocations = [];
	var existingPaidLocations = [];

	if (product?.delivery_locations?.length > 0) {
		product.delivery_locations.forEach((loc) => {
			let data = JSON.parse(loc.data);
			if (loc.charges < 1) {
				existingLocations.push({ location: loc.location, days: data?.days });
			} else {
				existingPaidLocations.push({ location: JSON.parse(loc.location), price: loc.charges, days: data?.days });
			}
		});
	}

	// Add an empty object if no locations were added from product.delivery_locations
	if (existingLocations.length === 0 || existingPaidLocations.length === 0) {
		checkForLatestLocations();
	}

	async function checkForLatestLocations() {
		await axiosCall("/api/inventorylatest", "get", {}, true)
			.then((res) => {
				if (res.data.data.delivery_locations.length > 0) {
					res.data.data.delivery_locations.forEach((loc) => {
						let locData = JSON.parse(loc.data);
						if (loc.charges < 1)
							existingLocations.push({ location: loc.location, days: locData?.days });
						else
							existingPaidLocations.push({ location: JSON.parse(loc.location), price: loc.charges, days: locData?.days });
					});
				}
			})
			.catch((error) => {
				//setMessage("Auth failure! Please create an account");
				//setIserror(true);
			});

		if (existingLocations.length === 0) {
			existingLocations.push({ location: '', days: '' });
		}

		if (existingPaidLocations.length === 0) {
			existingPaidLocations.push({ location: [], price: '', days: '' });
		}
	}

	const [deliveryLocations, setDeliveryLocations] = useState(existingLocations);

	const handleLocationChange = (location, index, days) => {
		const newLocations = [...deliveryLocations];
		newLocations[index] = { location: location, days: parseInt(days) };
		setDeliveryLocations(newLocations);
	};

	const addMoreLocation = () => {
		setDeliveryLocations([...deliveryLocations, { location: '', days: '' }]);
	};

	const handleDeleteLocation = (index) => {
		const newLocations = [...deliveryLocations];
		newLocations.splice(index, 1);
		setDeliveryLocations(newLocations);
	};

	// Convert dzongkhags to an array of [key, value] pairs
	const coreLocationsArray = Object.entries(coreLocations);

	const [paidLocations, setPaidLocations] = useState(existingPaidLocations);

	const handlePaidLocations = (idx, selectedValues, cost, days) => {
		const newPaidLocations = [...paidLocations];
		newPaidLocations[idx] = { 'location': selectedValues, 'price': parseInt(cost, 10), 'days': parseInt(days) };
		setPaidLocations(newPaidLocations);
	};

	const handlePaidLocAddRow = () => {
		setPaidLocations([...paidLocations, { 'location': [], 'price': '', 'days': '' }]);
	};

	const handleDeletePaidLoc = (index) => {
		const newPaidLocations = [...paidLocations];
		newPaidLocations.splice(index, 1);
		setPaidLocations(newPaidLocations);
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonRow>
						<IonCol size="9">
							<IonTitle>Enter Product Info</IonTitle>
						</IonCol>
						<IonCol size="3" className="ion-float-end">
							<IonIcon className="ion-float-right" icon={closeOutline} onClick={() => onDismiss()} />
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonGrid className="ion-padding">
					<IonRow>
						<IonCol size='12'>
							<IonList>
								<IonItem>
									<IonSelect
										label="Category"
										interface="popover"
										placeholder="Select Category"
										compareWith={compareWith}
										value={category} onIonChange={(e) => setCategory(e.target.value)}>
										{categories.map((type) => (
											<IonSelectOption key={type.id} value={type.id}>
												{type.description}
											</IonSelectOption>
										))}
									</IonSelect>
								</IonItem>
							</IonList>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<Swiper slidesPerView={5} initialSlide={2} speed={400} spaceBetween={10} centeredSlides={true}
								zoom={true} key={images.map(slide => slide.id).join('_')}>
								{images.map((image, i) => {
									//if(!image.src.includes('undefined')) { 
									return <SwiperSlide key={i}>
										<div className="swiper-zoom-container">
											<IonIcon className="img-delete" icon={closeOutline} onClick={(e) => deleteImage(e, i)} />
											<IonImg src={image.src} onClick={() => slideClick(i)} />
										</div>
									</SwiperSlide>
									//}
								}
								)}
							</Swiper>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<IonButton fill="outline" color="drukre" onClick={takePicture}>
								<IonIcon icon={cameraOutline} /> &nbsp; Take Picture
							</IonButton>
						</IonCol>
						<IonCol className="ion-text-end">
							<div style={{ position: 'relative', display: 'inline-block' }}>
								<input
									type="file"
									multiple
									onChange={handleFileChange}
									id="multi-image-upload"
									style={{
										position: 'absolute',
										left: 0,
										top: 0,
										opacity: 0,
										width: '100%',
										height: '100%',
										cursor: 'pointer',
									}}
								/>
								<IonButton fill="outline" color="drukre" onClick={openFileDialog}>
									<IonIcon icon={cloudUploadOutline}></IonIcon> &nbsp; Upload Multiple
								</IonButton>
							</div>
						</IonCol>
					</IonRow>

					<IonRow className="ion-margin-bottom">
						<IonCol size="12">
							<IonInput labelPlacement="floating" fill="outline" label="Title" value={label} onIonInput={(e) => setLabel(e.target.value)}></IonInput>
						</IonCol>
					</IonRow>

					<IonRow className="ion-margin-bottom">
						<IonCol size="12">
							<IonTextarea label="Description" labelPlacement="floating" fill="outline" placeholder="Enter Description"
								value={info} onIonInput={(e) => setInfo(e.target.value)}
								counter={true}
								maxlength={2000}
								autoGrow={true}
								counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
							></IonTextarea>
						</IonCol>
					</IonRow>

					<IonRow className="ion-margin-bottom">
						<IonCol size="12">
							{/*
							<IonItem>
								<IonIcon icon={locationOutline} color="drukre" />
								<IonInput label="Location" value={location} onIonChange={e => setLocation(e.target.value)}></IonInput>
							</IonItem>
							
							<IonItem>
								<IonIcon icon={locationOutline} color="drukre" />
								<IonSearchbar onClearInput={(e) => setLocationResults([])} debounce={1000} label="Location" value={location} placeholder="Enter Location" name="location" onIonInput={(ev) =>handleLocationInput(ev)}>
								</IonSearchbar>
							</IonItem>
							<IonList>
								{Object.entries(locationResults).map(([dzo, gew]) => (
									<IonItem key={gew} onClick={(ev) => locationListClick(ev, gew)}>{gew}</IonItem>
								))}
							</IonList>
							*/}
							<IonItem>
								<IonLabel>Delivery/Pickup Locations</IonLabel>
							</IonItem>
							{deliveryLocations.map((location, index) => (
								<IonRow key={index}>
									<IonCol size="7">
										<IonInput
											labelPlacement="floating" fill="outline"
											label="Locations"
											placeholder="Enter location here"
											key={index}
											type="text"
											name={location.location}
											value={location.location}
											onIonInput={(e) => handleLocationChange(e.detail.value, index, location.days)} />
									</IonCol>
									<IonCol size="3">
										<IonInput label="Days to pick" type="number" labelPlacement="floating"
											fill="outline" value={location.days}
											onIonChange={e => handleLocationChange(location.location, index, e.detail.value)} />
									</IonCol>
									<IonCol size="2">
										<IonButton color="danger" onClick={() => handleDeleteLocation(index)}>
											<IonIcon icon={closeOutline} />
										</IonButton>
									</IonCol>
								</IonRow>
							))
							}
							<IonButton className="ion-float-right" onClick={addMoreLocation}>Add More Locations</IonButton>
							{/* Render more inputs as needed */}
						</IonCol>
					</IonRow>

					<IonRow className="ion-justify-content-start ion-align-items-center">
						<IonCol size="12">
							<IonItem>
								<IonLabel>Paid Delivery Locations</IonLabel>
							</IonItem>
							{paidLocations.map((ploc, idx) => (
								<IonRow key={idx}>
									<IonCol size="5">
										<IonSelect key={idx} multiple={true} labelPlacement="floating" fill="outline"
											value={ploc.location} onIonChange={e => handlePaidLocations(idx, e.detail.value, ploc.price, ploc.days)}>
											{coreLocationsArray.map((coreLoc, index) => (
												<IonSelectOption key={index} value={coreLoc[0]}>
													{coreLoc[1]}
												</IonSelectOption>
											))}
										</IonSelect>
									</IonCol>
									<IonCol size="3">
										<IonInput label="Nu." type="number" labelPlacement="floating" fill="outline" value={ploc.price} onIonChange={e => handlePaidLocations(idx, ploc.location, e.detail.value, ploc.days)} />
									</IonCol>
									<IonCol size="3">
										<IonInput label="Days to deliver" type="number" labelPlacement="floating" fill="outline" value={ploc.days} onIonChange={e => handlePaidLocations(idx, ploc.location, ploc.price, e.detail.value)} />
									</IonCol>
									<IonCol size="1">
										<IonButton color="danger" onClick={() => handleDeletePaidLoc(idx)}>
											<IonIcon icon={closeOutline} />
										</IonButton>
									</IonCol>
								</IonRow>
							))}
							<IonButton className="ion-float-right" onClick={handlePaidLocAddRow}>Add New Location</IonButton>
						</IonCol>
					</IonRow>

					<IonRow className="ion-justify-content-start ion-align-items-center">
						<IonCol size="12">
							<IonInput label="Nu." type="number" labelPlacement="floating" fill="outline" value={price} onIonInput={(e) => setPrice(e.target.value)}></IonInput>
							<IonIcon icon={pricetagOutline} color="drukre" className="ion-padding-horizontal" /><IonNote> A commission may be added by EThrom. The price is tax inclusive.</IonNote>
						</IonCol>
					</IonRow>

					<IonRow className="ion-justify-content-start ion-align-items-center">
						<IonCol size="12">
							<IonInput label="Stock" type="number" labelPlacement="floating" fill="outline" value={stock} onIonInput={(e) => setStock(e.target.value)}></IonInput>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol size='12'>
							<IonList>
								<IonSelect
									label="Condition"
									placeholder="Condition of Item"
									labelPlacement="floating" fill="outline"
									interface="popover"
									value={condition} onIonChange={(e) => setCondition(e.target.value)}>
									{conditions.map((type) => (
										<IonSelectOption key={type.name} value={type.name}>
											{type.label}
										</IonSelectOption>
									))}
								</IonSelect>
							</IonList>
						</IonCol>
					</IonRow>

					{/*}
					<IonRow>
						<IonCol size='12'>
							<IonList>
								<IonItem>
									<IonSelect
										label="Status"
										placeholder="Would you like to publish it?"
										interface="popover"
										value={status} onIonChange={(e) => setStatus(e.target.value)}>
										{publishStatuses.map((type) => (
											<IonSelectOption key={type.name} value={type.name}>
												{type.label}
											</IonSelectOption>
										))}
									</IonSelect>
								</IonItem>
							</IonList>
						</IonCol>
					</IonRow>
					*/}
				</IonGrid>
			</IonContent>
			<IonFooter>
				<IonRow>
					<IonCol size="12" className="ion-text-end">
						{id && (
							<IonButton fill="outline" color="danger"
								onClick={() =>
									presentAlert({
										header: 'Are you sure you want to proceed',
										subHeader: '',
										message: 'Once you archive, it will not be available for sale.',
										buttons: [
											{
												text: 'Cancel',
												role: 'cancel',
											},
											{
												text: 'Confirm',
												role: 'confirm',
												handler: () => {
													archiveProduct(true);
												},
											}
										],
									})
								}
							>
								<IonIcon icon={alertOutline} />&nbsp;
								Archive Product
							</IonButton>
						)}
						<IonButton color="drukre" onClick={() => save()}>
							<IonIcon icon={navigateOutline} />&nbsp;
							Save
						</IonButton>
					</IonCol>
				</IonRow>
			</IonFooter>
		</IonPage>
	);
}